import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import bannerVideo from "../assets/videos/alignerbase-full-banner-video.mp4"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import { Helmet } from "react-helmet"
import LazyLoad from "react-lazyload"


const IndexPage = ({ data }) => {
  // const [isMobile, setIsMobile] = useState(false)

  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent
  //   const mobileAgents = ["Android", "iPhone"]
  //   const isMobile = mobileAgents.some(agent => userAgent.includes(agent))
  //   setIsMobile(isMobile)
  // }, [])
  const advantageImageData = () => {
    const advImages = [
      {
        title: "Market-beating Price",
        description: (
          <span>
            AlignerBase provides high-quality treatment plans at just $59 for
            Pay As You Go clients, or even less for Enterprise clients. View the
            {" "}
            <a href="/plan-and-pricing">
              <span style={{ color: "blue" }}>Plans and Pricing</span>
            </a>{" "}
            page to learn more.{" "}
          </span>
        ),
      },
      {
        title: "Expert Design Team",
        description:
          "Our designers have collectively delivered more than 100,000 designs and worked for pioneering companies in the aligner market.",
      },
      {
        title: "Mandatory Orthodontist Review",
        description:
          "Each AlignerBase plan passes through detailed Quality Control performed by qualified Orthodontists before being shared with you.",
      },
      {
        title: "48-hour Turnaround",
        description:
          "We aim to make plans ready for download within 48-hours of case submission and acceptance, although in some cases this may take longer.",
      },
      {
        title: "Unlimited Revisions",
        description:
          "We strive for a 100% first-time approval rate, but welcome unlimited treatment plan revision requests until you are satisfied.",
      },
      {
        title: "Secure Platform",
        description:
          "The AlignerBase Platform ensures that your patient's PHI (Protected Health Information) is encrypted, anonymized and never shared with 3rd parties.",
      },
    ]

    data.advantagesImgs.edges.map((imgItem, index) => {
      advImages[index].imgSrc = imgItem.node.fluid
    })

    return advImages
  }

  const serviceData = () => {
    const serviceWithImageData = [
      {
        title: "Crowding",
      },
      {
        title: "Spacing",
      },
      {
        title: "Deep Bite",
      },
      {
        title: "Overjet",
      },
      {
        title: "Underbite",
      },
      {
        title: "Open Bite",
      },
      {
        title: "Midline Shift",
      },
    ]

    data.servicesImgs.edges.map((imgItem, index) => {
      serviceWithImageData[index].imgSrc = imgItem.node.fluid
    })

    return serviceWithImageData
  }

  const workflowImageData = () => {
    const workflowImages = [
      {
        title: "Scan",
        description:
          "Log in to your AlignerBase platform account, fill in the patient Rx, upload STL scans and patient photos.",
      },
      {
        title: "Design",
        description:
          "We review your case, design the treatment plan, perform a quality check, and upload the design for your approval.",
      },
      {
        title: "Print",
        description:
          "Once you approve the treatment plan, you can download the STL print file and proceed straight to production.",
      },
    ]

    data.workflowImgs.edges.map((imgItem, index) => {
      workflowImages[index].imgSrc = imgItem.node.fluid
    })

    return workflowImages
  }

  const RenderAdvantageSectionCard = ({ imgSrc, title, description }) => {
    return (
      <div className="section__inner__card-container__card">
        <Img fluid={imgSrc} alt={imgSrc.src} />
        <h4 className="section__inner__card-container__card__heading">
          {title}
        </h4>
        <p>{description}</p>
      </div>
    )
  }

  const RenderServiceSectionCard = ({ imgSrc, title }) => {
    return (
      <div className="section__inner__img-container__service-item flex flex-col gap-4 items-center">
        <Img fluid={imgSrc} alt={imgSrc.src} />
        <p className="font-black">{title}</p>
      </div>
    )
  }

  const RenderWorkflowSectionCard = ({ imgSrc, title, description }) => {
    return (
      <div className="workflow-section__card-container__card">
        <Img fluid={imgSrc} alt={imgSrc.src} />
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      const bannerVideo = document.querySelector("#banner-video")
      bannerVideo && bannerVideo.play()
    }, 1000)
  }, [])

  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="AlignerBase - Expert Clear Aligner Treatment Planning. High-quality, cost-effective STL files for your dental practice or lab within 48 hours. Try us for free!"
        />
      </Helmet>
      <Seo title={`Aligner treatment planning and designing service`} />
      <div id="home">
        <div className="banner">
          <div className="banner__bg-overlay">
            {/* Gradient Image for small screens only */}
            <div className="banner__bg-image overlay block sm:hidden"></div>

            {/* {!isMobile ? ( */}
            <LazyLoad once>
              <video
                loop
                autoPlay={true}
                muted={true}
                id={"banner-video"}
                className="banner__video hidden sm:block"
              >
                <source src={bannerVideo} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </LazyLoad>
            {/* ) : null} */}
            <div className="banner__inner">
              <h2 className="banner__inner__heading">
                AlignerBase delivers high-precision clear aligner treatment
                plans for aligner manufacturers.
              </h2>
              <div className="divider"></div>
              <h4 className="banner__inner__sub-heading">
                At AlignerBase, we deliver tailored STL files for aligner
                treatment via our secure platform, serving dentists, dental
                labs, and aligner companies –{" "}
                {/* <span style={{ fontWeight: "900px", fontSize: "25px" }}> */}
                for $59 or less per plan.
                {/* </span> */}
              </h4>
              <div  className="button-group sm:w-full lg:w-3/4">
                <div className="flex  justify-start ">
                <div className="banner__inner__offer md:mr-auto mr-2">
                  <Link to={"/doctor-registration"}>
                    <button className="banner__inner__offer-btn">
                      Sign Up for Trial Case
                    </button>
                  </Link>
                </div>
                <div className="banner__inner__offer">
                  <Link to={"/plan-and-pricing"}>
                    <button className="banner__inner__offer-btn">
                      View Plans & Pricing
                    </button>
                  </Link>
                </div>
                </div>
                <div className="banner__inner__offer">
                  <Link to={"/private-labelling-solution"}>
                    <button className="banner__inner__offer-btn" style={{width:"100%"}}>
                     Try Our New Revolutionary Private Labelling Solution
                    </button>
                  </Link>
                </div>

                
              </div>

            </div>
          </div>
        </div>

        {/* Intro Section */}
        <section className="section intro-section">
          <div className="section__inner">
            <div>
              <p>
                We believe doctors should be able to maximize clinic hours
                towards patient care, not running CAD software.
              </p>
              <p>
                AlignerBase makes it affordable to outsource aligner setups
                without compromising treatment quality and precision, so you can
                spend time on what matters most.
              </p>
              <p>
                Our secure cloud-based case management platform ensures
                predictability by letting you track treatment plans at each
                stage of the design workflow and communicate directly with your
                design team.
              </p>
              <p>
                <Link to="/doctor-registration">
                  <span style={{ color: "blue" }}>Sign up today</span>
                </Link>{" "}
                and send us a trial case free of charge - for a limited time
                only.
              </p>
            </div>
            <div className="section__inner__img-wrapper">
              <Img
                fluid={data.introImage.childImageSharp.fluid}
                alt={"AlignerBase intro"}
              />
            </div>
          </div>
        </section>

        {/* Advantages Section */}
        <section className="section adv-section">
          <div className="section__bg-overlay overlay"></div>
          <div className="section__bg-image overlay"></div>
          <div className="section__inner">
            <h2 className="section__heading">The AlignerBase Difference</h2>
            <div className="section__divider divider"></div>
            <p className="section__text"></p>
            <div className="section__inner__card-container">
              {[...advantageImageData()].map((item, index) => (
                <RenderAdvantageSectionCard
                  key={item.title}
                  title={item.title}
                  imgSrc={item.imgSrc}
                  description={item.description}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Get-more info Section */}
        <section className="section-get-more-info">
          <h3>Connect with us to learn more about the AlignerBase Platform.</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <Link to={"/contact-us"}>
              <button className="">Contact Us</button>
            </Link>
            <Link to={"/doctor-registration"}>
              <button>Sign Up</button>
            </Link>
          </div>
        </section>

        {/* Workflow Section */}
        <section className="section workflow-section">
          <div className="section__bg-overlay workflow-section__bg-overlay overlay"></div>
          <div className="section__inner">
            <h2 className="section__heading">Our Workflow</h2>
            <div className="section__divider divider"></div>
            <p className="section__text"></p>
            <div className="workflow-section__card-container">
              {[...workflowImageData()].map((item, index) => (
                <RenderWorkflowSectionCard
                  key={item.title}
                  title={item.title}
                  imgSrc={item.imgSrc}
                  description={item.description}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Services Section */}
        <section className="section service-section">
          <div className="section__inner pt-0">
            <h2 className="section__heading">What We Treat</h2>
            <div className="section__divider divider"></div>
            <p className="section__text"></p>
            <div className="section__inner__img-container">
              {[...serviceData()].map((item, index) => (
                <RenderServiceSectionCard
                  key={item.title}
                  title={item.title}
                  imgSrc={item.imgSrc}
                />
              ))}
            </div>
            <Link to={"/services"}>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "3rem",
                  color: "blue",
                }}
              >
                Learn more about our services here
              </div>
            </Link>
          </div>
        </section>

        {/* Last Section */}
        <section className="section turnaround-section">
          <div className="section__bg-overlay overlay"></div>
          <div className="section__bg-image turnaround-section__bg-image overlay"></div>
          <div className="section__inner max-width-780">
            <h2 className="section__heading">
              Higher aligner margins begin with affordable treatment plans
            </h2>
            <div className="section__divider divider"></div>
            <p className="section__text">
              Sign up with AlignerBase today and maximize your clear aligner
              potential.
            </p>
            <div className="text-center">
              <Link to={"/doctor-registration"}>
                <button className="turnaround-section__btn">Get Started</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div className="flex justify-center">
        <CookieConsent
          location="bottom"
          style={{
            background: "#43ACCC",
            color: "#fff",
            width: "70%",
            margin: "0 auto",
            borderRadius: "8px",
            left: "auto",
            bottom: "10px",
          }}
          enableDeclineButton
          buttonText="Accept"
          declineButtonText="Reject"
          cookieName="gatsby-gdpr-google-analytics"
          declineButtonStyle={{
            backgroundColor: "#001a1c",
            width: "100px",
            color: "#fff",
            fontSize: "14px",
            borderRadius: "6px",
          }}
          buttonStyle={{
            backgroundColor: "#001a1c",
            color: "#fff",
            width: "100px",
            fontSize: "14px",
            borderRadius: "6px",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    advantagesImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: { originalName: { regex: "/alignerbase-home-adv-img-*/" } }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 150, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    servicesImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: { originalName: { regex: "/alignerbase-home-service-img-*/" } }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 200, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    workflowImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: { originalName: { regex: "/alignerbase-home-workflow-img-*/" } }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 130, maxHeight: 130) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    introImage: file(relativePath: { eq: "intro-right.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
